import pulse from '@finn-no/pulse-sdk';

export function trackBannerClick(buttonText, cardId) {
	pulse.trackEvent({
		type: 'Click',
		intent: 'Open',
		name: 'Braze content card',
		object: {
			type: 'UIElement',
			elementType: 'Button',
			position: `Frontpage:${buttonText}`,
			name: cardId,
		},
		vertical: { name: 'common_pages' },
	});
}

export function trackViewEvent(buttonText, cardId) {
	pulse.trackPageView({
		name: 'Braze content card',
		intent: 'Show',
		object: {
			type: 'UIElement',
			elementType: 'Card',
			position: `Frontpage:${buttonText}`,
			name: cardId,
		},
		vertical: { name: 'common_pages' },
	});
}
